/* eslint-disable no-new-func */
import React from "react"
import io from "socket.io-client"

import { getAuthSocketData } from "../../utils/dataUtility"
import { log } from "../../utils/common"
import { SOCKET_EVENTS } from "../../constants/socket"

import { updateAgentMonitoringState } from "./actions"
import {
  AM_MAKE_SOCKET_CONNECTION,
  AM_CALL_SOCKET_METHOD,
  AM_SOCKET_DISCONNECT,
  AM_EMIT_CUSTOM_EVENT,
} from "./actiontypes"

const registerSocketListener = (store, socket) => {
  socket.on(SOCKET_EVENTS.CONNECT, () => {
    store.dispatch(
      updateAgentMonitoringState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
    store.dispatch(
      updateAgentMonitoringState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.ERROR, () => {
    store.dispatch(
      updateAgentMonitoringState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.DISCONNECT, reason => {
    if (reason === "io server disconnect") socket.connect()
    store.dispatch(
      updateAgentMonitoringState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.BRAND_LIVE_STATS, payload => {
    log(SOCKET_EVENTS.BRAND_LIVE_STATS, payload)
    if (payload && payload.data) {
      store.dispatch(updateAgentMonitoringState({ brand_stats: payload.data }))
    }
  })

  socket.on(SOCKET_EVENTS.GROUP_LIVE_STATS, payload => {
    log(SOCKET_EVENTS.GROUP_LIVE_STATS, payload)
    if (payload && payload.data && payload.data.group && payload.data.columns) {
      payload.data.columns.forEach(col => {
        if (col.sorter) col.sorter = new Function("return " + col.sorter)()
        if (col.getCellHtml) {
          const cellHtml = new Function("return " + col.getCellHtml)()
          col.render = (text, record) => {
            const html = cellHtml(text, record)
            return <div dangerouslySetInnerHTML={{ __html: html }} />
          }
        }
      })
      store.dispatch(
        updateAgentMonitoringState({
          groups_stats: payload.data.group,
          groups_columns: payload.data.columns,
        })
      )
    }
  })

  socket.on(SOCKET_EVENTS.AGENT_LIVE_STATS, payload => {
    log(SOCKET_EVENTS.AGENT_LIVE_STATS, payload)
    if (
      payload &&
      payload.data &&
      payload.data.columns &&
      payload.data.agentList
    ) {
      payload.data.columns.forEach(col => {
        if (col.sorter) col.sorter = new Function("return " + col.sorter)()
        if (col.getCellHtml) {
          const cellHtml = new Function("return " + col.getCellHtml)()
          col.render = (text, record) => {
            const html = cellHtml(text, record)
            return <div dangerouslySetInnerHTML={{ __html: html }} />
          }
        }
      })
      store.dispatch(
        updateAgentMonitoringState({
          agents_stats: payload.data.agentList,
          agents_columns: payload.data.columns,
          agents_stats_loading: false,
        })
      )
    }
  })
}

const middleware = () => {
  let socket = null
  return store => next => action => {
    switch (action.type) {
      case AM_MAKE_SOCKET_CONNECTION:
        if (socket !== null) socket.close()
        const AUTH_SOCKET_DATA = getAuthSocketData()
        fetch("https://api64.ipify.org?format=json")
          .then(response => response.json())
          .then(data => {
            AUTH_SOCKET_DATA.query.publicIP = data.ip
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
          .catch(() => {
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
        break

      case AM_CALL_SOCKET_METHOD:
        if (socket) socket[action.payload]()
        break

      case AM_EMIT_CUSTOM_EVENT:
        if (socket && action.event) {
          if (action.callback)
            socket.emit(action.event, action.payload, action.callback)
          else socket.emit(action.event, action.payload)
        }
        break

      case AM_SOCKET_DISCONNECT:
        if (socket !== null) socket.close()
        socket = null
        break

      default:
        return next(action)
    }
  }
}

export default middleware()
